import Spinner from 'vue-number-input-spinner';
import { BLACKLIST_NON_AUTOSELECTABLE } from '@/settings/Products';
import { MEMBERSHIP_APPLIES_IN } from '@/settings/Purchase';
import Products from '@/util/Products';
import Packages from '@/util/GeneralInformation';

Spinner.watch.value = function changeValue(val) {
	this.numericValue = val;
};

export default {
	components: { Spinner },
	data() {
		return {
			memberProd: new Products(),
			packData: new Packages(),
			prodData: new Products(),
			activePackageId: 0,
			purchase: {},
			incrementedProducts: {},
			subtotal: 0,
			exchange_subtotal: 0,
			price_format: '',
			exchange_price_format: '',
		};
	},
	computed: {
		memberProducts() {
			try {
				return this.memberProd.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingMemberProducts() {
			try {
				return this.memberProd.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasMemberProducts() {
			return !!this.memberProducts.length;
		},
		packageStep() {
			return Object.keys(this.steps).find((item) => this.steps[item].validation === 'product');
		},
		packages() {
			try {
				return this.packData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingPackages() {
			try {
				return this.packData.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasPackages() {
			return !!this.packages.length;
		},
		activePackage() {
			const [response] = this.packages.filter((item) => {
				if (item.id === this.activePackageId) {
					return item;
				}
				return null;
			});
			if (typeof response === 'undefined') {
				return {};
			}
			return response;
		},
		savedPackage() {
			const step = this.packageStep;
			const data = { ...this.getStepInformation(step) };
			return data;
		},
		orderedPackages() {
			const orderedNumbers = {};
			this.packages.forEach((item) => {
				orderedNumbers[item.attributes.sort_order] = item.id;
			});
			return orderedNumbers;
		},
		totalVolume() {
			try {
				let bvs = 0;
				let subtotal = 0;
				let exchangeSubtotal = 0;
				const products = {};
				Object.keys(this.purchase).forEach((sku) => {
					const product = this.products.find((prod) => prod.attributes.sku === sku);
					if (typeof product !== 'undefined') {
						// BV Calculation
						// if (this.hasDiscount(product)) {
						// 	// console.log('0.1 PackBv +', (this.discount(product).bvs * this.purchase[sku]));
						// 	bvs += (this.discount(product).bvs * this.purchase[sku]);
						// } else {
						// 	// console.log('0.2 PackBv +', (product.attributes.bvs * this.purchase[sku]));
						// 	bvs += (product.attributes.bvs * this.purchase[sku]);
						// }
						bvs += (product.attributes.bvs * this.purchase[sku]);

						// Subtotal Calculation
						subtotal += (product.attributes.price_amount * this.purchase[sku]);
						exchangeSubtotal += (this.extractPriceAmount(product.attributes.exchange_price) * this.purchase[sku]);
					}

					if (sku !== '' && this.purchase[sku] > 0) {
						products[sku] = this.purchase[sku];
					}
				});

				const lastPackageId = Object.values(this.orderedPackages)[Object.keys(this.orderedPackages).length - 1];
				if (this.activePackageId < lastPackageId) {
					const orderActivePackage = this.activePackage.attributes.sort_order;
					const nextPackageId = this.orderedPackages[orderActivePackage + 1];
					const nextPackage = this.packages.filter((item) => item.id === String(nextPackageId));
					if (typeof nextPackage[0] !== 'undefined' && bvs >= nextPackage[0].attributes.volume_required) {
						this.activePackageId = nextPackage[0].id;

						Object.keys(products).forEach((sku) => {
							this.$set(this.purchase, sku, products[sku]);
						});
					}
				}
				subtotal += this.getMembershipSubtotal();
				exchangeSubtotal += this.getMembershipSubtotal(true);

				this.exchange_subtotal = exchangeSubtotal;
				this.subtotal = subtotal;
				return bvs;
			} catch (error) {
				return 0;
			}
		},
		products() {
			try {
				const mainProducts = [];
				const otherProducts = [];
				const productsData = this.prodData.data.response.data.data;
				productsData.forEach((item) => {
					// The '[price]' string will be replaced by the subtotal amount
					if (this.price_format.length === 0) {
						this.price_format = item.attributes.price.replace(item.attributes.price_amount, '[price]');
					}
					if (this.exchange_price_format.length === 0) {
						this.exchange_price_format = item.attributes.exchange_price.replace(this.extractPriceAmount(item.attributes.exchange_price, true), '[price]');
					}
					if (item.attributes.category_code === 'main') {
						mainProducts.push(item);
					} else if (item.attributes.category_code === 'other') {
						otherProducts.push(item);
					}
				});
				return [...mainProducts, ...otherProducts];
			} catch (error) {
				return [];
			}
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasProducts() {
			return !!this.products.length;
		},
	},
	methods: {
		loadPackageInfo() {
			this.packData.getPackages().then(() => {
				if (this.hasPackages) {
					let savedPackage = '';
					if (typeof this.savedPackage.product !== 'undefined') {
						savedPackage = this.packages.find((item) => item.attributes.code_name === this.savedPackage.product.package_code_name);
					}

					if (typeof savedPackage === 'object' && typeof savedPackage.id !== 'undefined') {
						this.activePackageId = savedPackage.id;
					} else {
						this.activePackageId = this.packages[0].id;
					}
				}
			});
		},
		loadProductsInfo() {
			this.prodData.getProducts(this.getStoredCountry(), undefined, { isRegister: true }).then(() => {
				this.price_format = '';
				this.exchange_price_format = '';
				if (typeof this.savedPackage.product === 'undefined' || Object.keys(this.savedPackage.product.products).length === 0) {
					this.fillInitialProducts();
				} else {
					this.products.forEach((item) => {
						const { sku } = item.attributes;
						let value = 0;
						try {
							value = this.getStoredProductQty(sku);
						} catch (error) {
							value = 0;
						}
						value = parseInt(value, 10);
						if (Number.isNaN(value)) {
							value = 0;
						}
						this.$set(this.purchase, sku, value);
					});
				}
			});
		},
		getStoredProductQty(sku) {
			if (Object.keys(this.savedPackage).length > 0) {
				const { products, package_code_name: currentPack } = this.savedPackage.product;
				if (Object.keys(products).length > 0 && typeof currentPack !== 'undefined' && currentPack !== null) {
					try {
						const qty = products[sku];
						if (typeof qty === 'undefined') {
							return 0;
						}
						return qty;
					} catch (error) {
						return 0;
					}
				}
			}
			return 0;
		},
		// discount(product) {
		// 	try {
		// 		if (Object.keys(product.attributes.discount).includes(this.activePackage.attributes.code_name)) {
		// 			return product.attributes.discount[this.activePackage.attributes.code_name];
		// 		}
		// 	} catch (error) {
		// 		return {};
		// 	}
		// 	return {};
		// },
		// hasDiscount(product) {
		// 	return !!(Object.keys(this.discount(product)).length);
		// },
		fillInitialProducts(id) {
			if (typeof id !== 'undefined') {
				this.activePackageId = id;
			}

			this.clearProducts();

			let volumeRequired = 0;
			if (Object.keys(this.activePackage).length > 0) {
				volumeRequired = this.activePackage.attributes.volume_required;
			}

			const product = {};
			for (let remainingVolume = volumeRequired; remainingVolume > 0;) {
				this.products.forEach((item) => {
					if (!BLACKLIST_NON_AUTOSELECTABLE.includes(item.attributes.sku)) {
						if (remainingVolume > 0 && remainingVolume < item.attributes.bvs) {
							remainingVolume = item.attributes.bvs;
						}
						if (item.attributes.bvs <= remainingVolume) {
							if (typeof product[item.attributes.sku] === 'undefined') {
								product[item.attributes.sku] = 0;
							}
							product[item.attributes.sku] += 1;
							remainingVolume -= item.attributes.bvs;
						}
					}
				});
			}

			Object.keys(product).forEach((sku) => {
				this.$set(this.purchase, sku, product[sku]);
			});
		},
		clearProducts() {
			const products = {};
			Object.keys(this.purchase).forEach((sku) => {
				if (sku !== '') {
					products[sku] = 0;
				}
			});

			Object.keys(products).forEach((sku) => {
				this.$set(this.purchase, sku, products[sku]);
			});
		},
		getMembershipSubtotal(isExchange = false) {
			let subtotal = 0;
			this.memberProducts.forEach((membership) => {
				let applyMembershipPrice = MEMBERSHIP_APPLIES_IN.default;
				const packageCodeName = this.activePackage.attributes.code_name;

				if (typeof MEMBERSHIP_APPLIES_IN[packageCodeName] !== 'undefined') {
					applyMembershipPrice = MEMBERSHIP_APPLIES_IN[packageCodeName].default;
					if (typeof MEMBERSHIP_APPLIES_IN[packageCodeName][this.getStoredCountry()] !== 'undefined') {
						applyMembershipPrice = MEMBERSHIP_APPLIES_IN[packageCodeName][this.getStoredCountry()];
					}
				}

				if (applyMembershipPrice) {
					if (isExchange) {
						subtotal += this.extractPriceAmount(membership.attributes.exchange_price);
					} else {
						subtotal += membership.attributes.price_amount;
					}
				}
			});

			return subtotal;
		},
		formatPriceNumber(amount) {
			// With commas and 2 decimals
			return amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		},
		extractPriceAmount(formattedPrice, withCommas = false) {
			// extract amount from formatted price (e.g. $1,150.24MXN => 1,150.24)
			let priceAmount = formattedPrice.match(/[+-]?(\d+,)?\d+(\.\d+)?/g);
			let price = 0;
			if (priceAmount !== null) {
				[priceAmount] = priceAmount;
				price = priceAmount;
				if (!withCommas) {
					price = parseFloat(priceAmount.replace(',', ''));
				}
			}

			return price;
		},
	},
};
